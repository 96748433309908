<template>
  <div class="modal-backdrop">
      <div class="modal">
          <div class="modal-container">
              <div class="modal-header">
                  <slot name="header">
                      <span class="display-1">
                        {{ content.heading }}
                      </span>
                  </slot>
                  <button
                      type="button"
                      class="btn-close"
                      @click="close"
                  >
                      x
                  </button>
              </div>
              <div class="modal-body">
                  <slot name="body">
                    <v-tabs
                      v-model="tab"
                      centered
                      light
                      icons-and-text
                      ref="tabs"
                    >
                      <v-tabs-slider color="#ff9800"></v-tabs-slider>
                      <v-tab
                        href="#tab-1"
                      >
                        {{ content.telephoneTitle }}
                        <v-icon
                          medium
                          color="#ff9800"
                          class="icon-message"
                        >
                          mdi-phone
                        </v-icon>
                      </v-tab>
                      <v-tab
                        href="#tab-2"
                      >
                        {{ content.mailTitle }}
                        <v-icon
                          medium
                          color="#ff9800"
                          class="icon-message"
                        >
                          mdi-email-send-outline
                        </v-icon>
                      </v-tab>
                      <v-tab
                        href="#tab-3"
                      >
                        {{ content.direccionTitle }}
                        <v-icon
                          medium
                          color="#ff9800"
                          class="icon-message"
                        >
                          mdi-map-marker
                        </v-icon>
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items
                      v-model="tab"
                    >
                      <v-tab-item
                        value="tab-1"
                      >
                        <div
                          align="center"
                          justify="center"
                          class="row-modal text-center"
                        >
                          <div 
                            class="tab-title"
                          >
                            {{ content.telephoneDesc }}
                          </div>
                          <div>
                            <a 
                              class="telephone-tab" 
                              href="tel:(55) 66460811">
                              (55) 66460811
                            </a>
                            <br>
                            <a 
                              class="telephone-tab" 
                              href="tel:(55) 66460811">
                                (55) 56895581
                            </a>
                          </div>
                        </div>
                      </v-tab-item>
                      <v-tab-item
                        value="tab-2"
                      >
                        <div class="error-message">
                          <v-icon
                            medium
                            color="#ff4d4d"
                            v-if="this.errorMessage"
                            class="icon-message"
                          >
                            mdi-alert
                          </v-icon>
                            {{ this.errorMessage }}
                        </div>
                        <div
                          class="warning-message"
                          v-if="errors.length"
                        >
                          <v-icon
                            medium
                            color="#ffb265"
                            class="icon-message"
                          >
                            mdi-comment-alert-outline
                          </v-icon>
                          <b>{{ content.warningMessage }}</b>
                          <ul>
                            <li v-for="(error, i) in errors"
                              :key="i"
                              :value="error"
                            >
                              {{ error }}
                            </li>
                          </ul>
                        </div>
                        <div
                          class="success-message"
                          v-if="this.successMessage"
                        >
                          <v-icon
                            medium
                            color="#5cd75c"
                            class="icon-message"
                          >
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                          <b>{{ this.successMessage }}</b>
                        </div>
                        <v-form ref="form">
                            <div class="container">
                                <v-row>
                                    <v-text-field
                                    :label="content.name"
                                    type="text"
                                    name="name"
                                    v-model="contactDetail['name']"
                                    ></v-text-field>
                                </v-row>
                                <v-row>
                                    <v-text-field
                                    :label="content.mail"
                                    type="text"
                                    name="email"
                                    v-model="contactDetail['email']"
                                    ></v-text-field>
                                </v-row>
                                <v-row>
                                    <v-textarea
                                    :label="content.message"
                                    type="text"
                                    name="message"
                                    rows="3"
                                    v-model="contactDetail['message']"
                                    ></v-textarea>
                                </v-row>
                                <v-row>
                                  <v-col class="text-center">
                                    <div 
                                      class="tab-title"
                                    >
                                      <a
                                        href="mailto:contacto@mezcalpopolvuh.com.mx"
                                      >
                                        contacto@mezcalpopolvuh.com.mx
                                      </a>
                                    </div>
                                  </v-col>
                                </v-row>
                            </div>
                            <div class="modal-footer">
                                <slot name="footer">
                                    <v-btn 
                                      light
                                      class="btn-orange" 
                                      color="#ff9800" 
                                      @click="checkForm"
                                      :disabled='isDisabled'
                                    >
                                      {{ content.submitBtn }}
                                    </v-btn>
                                </slot>
                            </div>
                        </v-form>
                      </v-tab-item>
                      <v-tab-item
                        value="tab-3"
                      >
                        <div
                          align="center"
                          justify="center"
                          class="row-modal text-center"
                        >
                          <div 
                            class="tab-title"
                          >
                            {{ content.direccionDesc }}
                          </div>
                          <div>
                            Erasmo Castellanos Quinto #352
                            <br>
                            Colonia Educación, CP 04400
                            <br>
                            Alcaldía de Coyoacán, Ciudad de México, México
                          </div>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </slot>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import {
  mapMutations,
} from 'vuex'

import axios from 'axios'

export default {
  data() {
      return {
        errorMessage: "",
        successMessage: "",
        contactInfo:false,
        errors: [],
        isDisabled:false,
        tab: "tab-2",
        contactDetail: { 
          name: "", 
          email: "", 
          message: "" 
          },
      };
  },
  computed: {
    content () {
      return this.$store.state.webpagecontent[0][this.$store.state.lang].contact
    },
    backendUrl () {
      return this.$store.state.backendUrl
    }
  },
  methods: {
    ...mapMutations(['showModal']),
    close() {
        this.showModal()
    },
    submit() {
      var params = new URLSearchParams()
      params.append('name', this.contactDetail.name)
      params.append('email', this.contactDetail.email)
      params.append('message', this.contactDetail.message)
      var _this = this;
      grecaptcha.ready(function() {
        grecaptcha.execute('6LceOOcaAAAAAIMXC9MLT1ZrEeIoyADGwDRP80Ev', {action: 'submit'}).then(function(token) {
          params.append('token', token)
          axios.post(_this.backendUrl + "send-contact/validate", params)
            .then(response => {
              _this.isDisabled = false
              _this.resetContactForm()
              if (response.status == 200) {
                _this.successMessage = _this.content.successMessage
                setTimeout(function(){ _this.close() }, 3000);
              }
              else{
                _this.errorMessage = _this.content.alertMessage
              }
            })
            .catch(error => {
              _this.errorMessage = _this.content.alertMessage
              _this.isDisabled = false
              _this.resetContactForm()
            });
        });
      });
    },
    resetContactForm: function(){
      this.contactDetail.name = ""
      this.contactDetail.email = ""
      this.contactDetail.message = ""
    },
    checkForm: function (e) {
      this.errors = []
      this.errorMessage = ""
      this.successMessage = ""

      if (!this.contactDetail.name) {
        this.errors.push(this.content.nameValidation)
      }
      if (!this.contactDetail.message) {
        this.errors.push(this.content.messageValidation)
      } else if(this.contactDetail.message.length <8){
        this.errors.push(this.content.messageValidationLength)
      }
      if (!this.contactDetail.email) {
        this.errors.push(this.content.emailValidation)
      } else if (!this.validEmail(this.contactDetail.email)) {
        this.errors.push(this.content.emailCorrectValidation)
      }

      if (!this.errors.length) {
        this.isDisabled = true
        this.submit()
      }

      e.preventDefault()
    },
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email)
    }
  },
}
</script>
<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto;
  }
  .modal {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto !important;
  }
  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }
  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #3F5465;
    justify-content: space-between;
  }
  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }
  .modal-body {
    position: relative;
    padding: 20px 10px;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #3F5465;
    background: transparent;
  }
  .btn-orange {
    color: #3F5465 !important;
    background: #ff9800;
    border: 1px solid #ffa21a;
    border-radius: 2px;
  }
  .modal-container {
      width: 450px;
      padding-left: 10px;
      padding-right: 10px;
  }
  .error-message {
    color: #ff4d4d;
    margin-top: 15px;
  }
  .warning-message {
    color: #ffb265;
    margin-top: 15px;
  }
  .success-message {
    color: #5cd75c;
    margin-top: 15px;
  }
  .icon-message {
    margin-right:10px;
  }
  .row-modal {
    padding-top:15px;
  }
  .tab-title {
    font-weight: 500;
    font-size: 17px;
    margin-bottom: 10px;
  }
  .telephone-tab {
    line-height: 2;
  }
</style>